//======> Tornado UI Core Base <=======//
import './Base/Tornado';
import Tornado from './Base/Tornado';
import './Base/wordpress';

document.addEventListener('DOMContentLoaded', DOMREADY => {
    //====> Header in Hero <====//
    Tornado.getElements('.main-header, .moving-element, .tornado-header').forEach(element => {
        Tornado.getElement('.header-included')?.prepend(element);
    });

    //====> Hero Slider <====//
    Tornado.slider('.hero-slider', {
        items      : 1,     //===> Display Slides Count
        duration   : 8000,  //===> Autoplay Duration
        pagination : true,   //===> Show Dots Pagination
        controls   : true,   //===> Show Arrows
        animation  : "gallery",    //===> Animation Mode [carousel, gallery]
        speed      : 1000,           //===> Animation Speed
        // autoplay   : false,
        // infinite   : false
    });

    //====> Carousel Slider <====//
    Tornado.getElements('.carousel-slider').forEach(element => {
        //====> Dynamic Options <====//
        var itemsNumber = element.getAttribute('data-items'),
            itemsSmall  = element.getAttribute('data-sm'),
            itemsMedium = element.getAttribute('data-md'),
            itemsLarge  = element.getAttribute('data-lg'),
            itemsxLarge  = element.getAttribute('data-xl'),
            controls    = element.getAttribute('data-controls'),
            cssClass    = element.getAttribute('data-class') || 'carousel-slider-outer',
            pagination  = element.getAttribute('data-pagination');

        //====> Create Slider <====//
        Tornado.slider(element, {
            duration   : 7000,
            speed      : 700,
            items      : parseInt(itemsNumber) || 1,
            pagination : pagination ? true : false,
            controls   : controls ? true : false,
            uniuqClass : cssClass,
            responsive : {
                small  : { items: parseInt(itemsSmall)  || 2},
                medium : { items: parseInt(itemsMedium) || 3},
                large  : { items: parseInt(itemsLarge)  || 4},
                xlarge : { items: parseInt(itemsxLarge)  || 5},
            }
        });
    });

    //====> Header in Hero <====//
    Tornado.getElement('.with-dark-header')?.querySelector('.tornado-header')?.classList.add('darker');

    //====> Categories Menu Toggle <====//
    Tornado.getElements('.menu-toggle').forEach(button => {
        button.addEventListener('click', event => {
            event.preventDefault();
            //===> Desktop Mode <===//
            if(Tornado.viewport.width() > 750) {
                Tornado.getNextSibling({
                    element : button,
                    filter : '.navigation-menu'
                })?.classList.toggle('closed');
            }
            //===> Mobile Mode <===//
            else {
                var MenuID = button.getAttrobute('data-id');
                Tornado.getElement(`#${MenuID}`).classList.add('active');
            }
        });
    });

    //====== Header Custom Menu Menu ======//
    Tornado.getElements('.tornado-header .custom-menu-btn').forEach(btn => {
        //====> Custom Menu <====//
        var mainMenu = Tornado.getElement('.tornado-header .custom-menu');
        //====> When Click Activator <====//
        btn.addEventListener('click', e => {
            e.preventDefault();
            btn.classList.toggle('active');
            mainMenu.classList.toggle('active');
        });
    });

    //==== Custom Modals ====//
    Tornado.getElements('[data-modal]').forEach(modalButton => {
        //==== Dynamic Form Subject ====//
        if (modalButton.classList.contains('subject-btn')) {
            var subjectControler = Tornado.getElement('#dynamic-subject .wpcf7-form-control[name="your-subject"]');
            subjectControler?.setAttribute('value', modalButton.getAttribute('data-subject'));
        }
        //==== Dynamic Video Popup ====//
        if (modalButton.hasAttribute('data-video')) {
            var videoUrl = modalButton.getAttribute('data-video'),
                videoElement = Tornado.getElement('#video-modal video');
            if (videoElement) videoElement.setAttribute('src',videoUrl);
        }
    });

    //====> Dynamic Word Coloring <====//
    Tornado.getElements('body:not(.wp-admin) .colored-word').forEach(title => {
        //====> Setup Properties <====//
        var titleContent = title.innerHTML,
            word_array = titleContent.split(/[ ]+/),
            lastword  = word_array.splice(-1);
        //====> Return Title <====//
        let theResult = `${word_array} <strong> ${lastword}</strong>`;
        title.innerHTML = theResult.replace(/,/g, ' ');
    });

    //====== Testimonials Audio =======//
    const stopAudio = () => {
        Tornado.getElements('.testimonail-block audio').forEach(audio => {
            audio.pause();
            audio.currentTime = 0;
            //======= Icon Swirch =======//
            audio.parentNode.querySelector('.play-btn').classList.add('ti-play');
            audio.parentNode.querySelector('.play-btn').classList.remove('ti-pause');
        });
    }

    Tornado.liveEvent('.testimonail-block .play-btn', 'click', event => {
        event.preventDefault();
        var button = event.target;
        stopAudio();
        //======= Icon Swirch =======//
        button.classList.toggle('ti-play');
        button.classList.toggle('ti-pause');
        //======== Play Audio =======//
        var audio = button.parentNode.querySelector('audio');
        audio.play();
        //======= on Ended =======//
        audio.onended = event => {
            //======= Icon Swirch =======//
            button.classList.add('ti-play');
            button.classList.remove('ti-pause');
        }
    });
});

