//======> Tornado UI <=======//
import Tornado from './Tornado';

//================> WordPress Support =================//
const WordPress = document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    //====> Icons Direction Fix <=======//
    // Tornado.getElements('[class*="ti-arrow-"],[class=*"ti-angle-"]').forEach(element => {
    //     //====> Get the Classes List <====//
    //     var classes = element.classList,
    //         newClasses;

    //     //====> Replace Direction Classes <====//
    //     if (classes.contains('ti-arrow-left')) {
    //         newClasses = classes.replace('ti-arrow-left',`ti-arrow-${Tornado.direction('start')}`);

    //     } else if (classes.contains('ti-arrow-right')) {
    //         newClasses = classes.replace('ti-arrow-right',`ti-arrow-${Tornado.direction('end')}`);

    //     } else if (classes.contains('ti-angle-left')) {
    //         newClasses = classes.replace('ti-angle-left',`ti-angle-${Tornado.direction('start')}`);

    //     } else if (classes.contains('ti-angle-right')) {
    //         newClasses = classes.replace('ti-angle-right',`ti-angle-${Tornado.direction('end')}`);
    //     }

    //     //====> Set the New Classes <====//
    //     if (newClasses) element.classList = newClasses
    // });

    //==== Remove Archives from the Title ====//
    var pageTitle = Tornado.getElement('.page-head h1'),
        oldTitle = pageTitle?.textContent,
        newTitle = oldTitle?.replace(/Archives|Archive|page|[0-9]|of/gi,'');

    if(pageTitle) pageTitle.textContent = newTitle;

    if (Tornado.direction('page') == 'rtl') {
        Tornado.getElements('.breadcrumb .breadcrumb_last').forEach(breadcrumbElement => {
            var elemTxt = breadcrumbElement.textContent;
            elemTxt = elemTxt.replace("Page", "");
            breadcrumbElement.textContent = elemTxt;
        });
    }

    /*===== Contact 7 =====*/
    Tornado.getElements('.wpcf7-form br').forEach(cf7_br => cf7_br.remove());
    Tornado.getElements('.wpcf7-form[dir],.wpcf7-form [dir]').forEach(element => element.removeAttribute('dir'));
});

export default WordPress;
